<template>
  <div class="wrap">
    <myHead></myHead>
    <div class="wrap_body">

      <div class="page_links">
        当前位置：
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/uc_order' }">订单管理</el-breadcrumb-item>
          <el-breadcrumb-item>关联订单商品</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="wrap_body_content">
        <div class="wrap_body_content_left">
          <div v-if="product_list.length > 0">
            <div class="wrap_body_content_left_goods" :class="{active: item.product_id == current_product.product_id}" v-for="item in product_list" @click="handleChangeProduct(item)">
              <div style="flex-shrink: 0;">
                <img class="wrap_body_content_left_goods_pic" :src="item.product_pic" />
              </div>
              <div class="wrap_body_content_left_goods_desc">
                <p class="title">{{item.product_name}}</p>
              </div>
            </div>
          </div>
          <div v-else>暂无产品</div>
        </div>

        <div class="wrap_body_content_right">
          <goodsConnectBox ref="goodsConnect" />
        </div>
      </div>
    </div>
    <myFoot></myFoot>
  </div>
</template>

<script>
import myHead from "@/components/myHead/myhead.vue";
import myFoot from "@/components/myFoot/myfoot.vue";
import ucMenu from "@/components/ucenterMenu/ucmenu.vue";
import {mapActions} from "vuex";
import goodsConnectBox from "@/components/goodsConnectBox/goodsConnectBox.vue";

export default {
  components: {goodsConnectBox, ucMenu, myFoot, myHead},
  data(){
    return {
      order_ids:"",
      product_list: [],
      current_product: {},
    }
  },
  created() {
    this.order_ids = this.$route.query.ids
    this.init();
  },
  methods:{
    ...mapActions({
      getOrderProductList:"ucenter/getOrderProductList",
    }),
    init(){
      this.getOrderProductList({
        data: {ids: this.order_ids},
        success:res=>{
          this.product_list = res.data
          if(res.data.length > 0){
            this.handleChangeProduct(res.data[0])
          }
        }
      })
    },
    handleChangeProduct(row){
      this.current_product = row;
      this.$refs.goodsConnect.init(row.product_id)
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap{
  &_body{
    height: 100%;
    margin: 0 auto;
    min-width: 1200px;

    .page_links{line-height: 1;font-size: 14px;color: #999999;display: flex;margin-top: 25px;}
    .page_links .el-breadcrumb__inner{font-weight: bold !important;color: #333 !important;}
    .page_links .el-breadcrumb__inner.is-link{font-weight: initial !important;color: #999 !important;}
    .page_links .el-breadcrumb__inner.is-link:hover{color: #f0423f !important;text-decoration: underline;}
    &_content{
      display: flex;
      gap: 20px;
      margin-top: 30px;
      &_left{
        width: 350px;
        padding: 10px;
        height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
        background: #ffffff;
        box-sizing: border-box;

        &_goods{
          display: flex;
          border: #c0c4cc solid 1px;
          border-radius: 6px;
          margin: 10px 0;
          padding: 20px 10px;
          box-sizing: border-box;
          &_pic{
            width: 40px;
            height: 40px;
            border-radius: 6px;
            object-fit: cover;
          }
          &_desc{
            width: 200px;
            padding-left: 20px;
            flex-shrink: 0;
            font-size:12px;
            box-sizing: border-box;
            .title{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }
        .active{
          border-color: #fa5757;
        }
      }


      &_left::-webkit-scrollbar {
        width: 3px;
      }

      &_left::-webkit-scrollbar-thumb {
        background: #DDDDDD;
        border-radius: 3px;
      }

      &_left::-webkit-scrollbar-track {
        background: #F2F2F2;
        border-radius: 3px;
      }

      &_right{
        width: 1200px;
        background: #ffffff;
      }
    }
  }
}
</style>